<div class="well2 p-20pt flex flex-column gap-15pt text-xl" [ngClass]="hostClasses">
  <div *ngIf="!booking?.isConfirmed">{{ "MYBOOKING.CALENDAR.STATE" | myBookingTranslate }}</div>
  <div *ngIf="booking?.isConfirmedOrPrev() && booking?.arrives_day_before">
    {{
      "MYBOOKING.CALENDAR.DAY_BEFORE_ARRIVAL.DATE_AND_ARRIVAL" | translate: {
        arrivalDate: booking.displayDayOfArrival,
        arriveAfter: booking?.workshop.arrive_before_close_day_before,
        closesAt: booking?.workshop.closes_at_day_before
      }
    }}
  </div>
  <div
    *ngIf="booking?.isConfirmedOrPrev() && booking?.arrives_day_before">
    {{
      "MYBOOKING.CALENDAR.DAY_BEFORE_ARRIVAL.DATE_AND_ARRIVAL_2" | translate: {
        date: booking.displayReservedDateWithYear,
        closesAt: booking?.workshop.closes_at_day_before
      }
    }}
  </div>
  <div
    *ngIf="booking?.isConfirmedOrPrev() && !booking?.arrives_day_before">{{ "MYBOOKING.CALENDAR.DATE_AND_ARRIVAL" | myBookingTranslate }}
  </div>
  <div>{{ "MYBOOKING.CALENDAR.JOB_TYPE" | myBookingTranslate }}</div>
  <div>{{ "MYBOOKING.CALENDAR.VEHICLE_INFO" | myBookingTranslate }}</div>
  <div *ngIf="booking?.isConfirmed && ical"><a [caryFileUrl]="ical" mediaType="text/calendar">
    <i class="fa fa-calendar mr-5pt"></i><span translate>MYBOOKING.CALENDAR.EXPORT_CALENDAR</span></a></div>
</div>

