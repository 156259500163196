import {Uuid} from '../uuid';
import {HasUUID} from '../has-uuid';
import {BookingState} from './booking-state';
import {MyVehicle} from '../my-vehicle/my-vehicle';
import {MyWorkshop} from '../my-workshop/my-workshop';
import {RikData} from '../rik-data';
import {Title} from '../title';
import {KlarnaStatus} from '../../shared/klarna/klarna-status';
import {MyAgreementSignStatus} from './my-agreement-sign-status';
import {JobType} from '../constants';
import {subDays} from "date-fns/subDays";
import {formatDate} from "date-fns/format";

export class Booking implements HasUUID {
  readonly uuid: Uuid;
  readonly key: string;
  readonly state: BookingState;
  readonly type: JobType;
  readonly reserved_date: Date | null;
  readonly arrives_day_before: boolean;
  readonly reserved_time: string;
  readonly insurance_company: string;
  readonly insurance_type_of_loss: string;
  readonly insurance_vat_subject: boolean;
  readonly courtesy_vehicle_type: string;
  readonly extra_wipers: boolean;
  readonly extra_washer_fluid: boolean;
  readonly extra_courtesy_vehicle_insurance: boolean;
  readonly extra_glass_treatment: boolean;
  readonly contact_name: string;
  readonly contact_phone: string;
  readonly contact_email: string;
  readonly user_name: string | null;
  readonly user_org_no: string | null;
  readonly vehicle: MyVehicle;
  readonly workshop: MyWorkshop;
  readonly agreement_link: string | null;
  readonly agreement_sign_status: MyAgreementSignStatus;
  readonly klarna_status: KlarnaStatus;

  constructor(data: any) {
    const rikData = new RikData(data);

    this.uuid = rikData.requireExists('uuid');
    this.key = rikData.requireExists('key');
    this.state = rikData.getRbType('state', BookingState);
    this.type = rikData.getEnumString('type', JobType);
    this.reserved_date = rikData.getDate('reserved_date');
    this.arrives_day_before = rikData.getBool('arrives_day_before');
    this.reserved_time = rikData.requireExists('reserved_time');
    this.insurance_company = rikData.requireExists('insurance_company');
    this.insurance_type_of_loss = rikData.requireString(
      'insurance_type_of_loss'
    );
    this.insurance_vat_subject = rikData.getBool('insurance_vat_subject');
    this.courtesy_vehicle_type = rikData.requireExists('courtesy_vehicle_type');
    this.extra_wipers = rikData.getBool('extra_wipers');
    this.extra_washer_fluid = rikData.getBool('extra_washer_fluid');
    this.extra_courtesy_vehicle_insurance = rikData.getBool(
      'extra_courtesy_vehicle_insurance'
    );
    this.extra_glass_treatment = rikData.getBool('extra_glass_treatment');
    this.contact_name = rikData.requireExists('contact_name');
    this.contact_phone = rikData.requireExists('contact_phone');
    this.contact_email = rikData.requireExists('contact_email');
    this.user_name = rikData.requireExists('user_name');
    this.user_org_no = rikData.requireExists('user_org_no');
    this.vehicle = rikData.requireObject('vehicle', MyVehicle);
    this.workshop = rikData.requireObject('workshop', MyWorkshop);
    this.agreement_link = rikData.getString('agreement_link');
    this.agreement_sign_status = rikData.getEnumString(
      'agreement_sign_status',
      MyAgreementSignStatus,
      MyAgreementSignStatus.NOT_APPLICABLE
    );
    this.klarna_status = rikData.getEnumString(
      'klarna_status',
      KlarnaStatus,
      KlarnaStatus.NOT_OK
    );
  }

  public hasCourtesyVehicle(): boolean {
    return this.hasCourtesyVehicleBicycle() || this.hasCourtesyVehicleCar();
  }

  public hasCourtesyVehicleCar(): boolean {
    return this.courtesy_vehicle_type === 'COURTESY_VEHICLE_TYPE_CAR';
  }

  public hasCourtesyVehicleBicycle(): boolean {
    return this.courtesy_vehicle_type === 'COURTESY_VEHICLE_TYPE_BICYCLE';
  }

  public get extrasSummary(): Title {
    const washers = this.extra_washer_fluid ? 'SERVICES.WASHER_FLUID' : '';
    const wipers = this.extra_wipers ? 'SERVICES.WIPERS' : '';
    const cvInsurance = this.extra_courtesy_vehicle_insurance
      ? 'SERVICES.COURTESY_VEHICLE_INSURANCE'
      : '';
    const glassTreatment = this.extra_glass_treatment
      ? 'SERVICES.GLASS_TREATMENT'
      : '';
    return Title.of(washers, wipers, cvInsurance, glassTreatment);
  }

  get displayReservedDate(): string {
    if (this.reserved_date === null) {
      return '';
    }

    return formatDate(this.reserved_date, 'd MMMM');
  }

  get displayReservedDateWithYear(): string {
    if (this.reserved_date === null) {
      return '';
    }

    return formatDate(this.reserved_date, 'd MMM yyyy');
  }

  get displayDayOfArrival(): string {
    if (this.dayOfArrival === null) {
      return '';
    }
    return formatDate(this.dayOfArrival, 'EEEE d MMMM');
  }

  get dayOfArrival(): Date | null {
    if (this.reserved_date === null) {
      return null;
    }

    return this.arrives_day_before ? subDays(this.reserved_date, 1) : this.reserved_date;
  }

  get canOnlySignInsuranceClaim(): boolean {
    return (
      this.agreement_sign_status === MyAgreementSignStatus.READY_TO_SIGN_CLAIM
    );
  }

  get canOnlySignCourtesyVehicle(): boolean {
    return (
      this.agreement_sign_status === MyAgreementSignStatus.READY_TO_SIGN_CV
    );
  }

  get canSignCvAgreement(): boolean {
    return this.canOnlySignCourtesyVehicle || this.canSignAllAgreements;
  }

  get canSignClaimAgreement(): boolean {
    return this.canOnlySignInsuranceClaim || this.canSignAllAgreements;
  }

  get canSignAllAgreements(): boolean {
    return (
      this.agreement_sign_status === MyAgreementSignStatus.READY_TO_SIGN_ALL
    );
  }

  get canSignAgreements(): boolean {
    return this.canSignCvAgreement || this.canSignClaimAgreement;
  }

  get hasSignedAgreements(): boolean {
    return this.agreement_sign_status === MyAgreementSignStatus.SIGNED;
  }

  public get isStonechip(): boolean {
    return this.type === JobType.Repair;
  }

  public get isWindshield(): boolean {
    return this.type === JobType.Windshield;
  }

  public get isJobtypeOther(): boolean {
    return this.type === JobType.Other;
  }

  public get isJobTypeSunFilm(): boolean {
    return this.type === JobType.SunFilm;
  }

  public get isJobTypeDabAntenna(): boolean {
    return this.type === JobType.DabAntenna;
  }

  public get isReplacement(): boolean {
    return this.isJobTypeReplacement(this.type);
  }

  public get isDropIn(): boolean {
    return this.type === JobType.Repair && this.reserved_time === null;
  }

  isSelected(serviceKey: string): boolean {
    switch (serviceKey) {
      case 'WIPERS':
        return this.extra_wipers;
      case 'WASHER_FLUID':
        return this.extra_washer_fluid;
      case 'GLASS_TREATMENT':
        return this.extra_glass_treatment;
      case 'COURTESY_VEHICLE_INSURANCE':
        return this.extra_courtesy_vehicle_insurance;
      default:
        console.warn('Service key not recognized', serviceKey);
        return false;
    }
  }

  isJobTypeReplacement(jobType: string): boolean {
    return (
      jobType === JobType.RearWindow ||
      jobType === JobType.SideWindow ||
      jobType === JobType.RoofWindow ||
      jobType === JobType.Windshield
    );
  }

  isConfirmedOrPrev(): boolean {
    return BookingState.isReservedOrConfirmed(this.state);
  }

  get isConfirmed(): boolean {
    return this.state.isConfirmed;
  }

  isReadyForDepartureOrPrev(): boolean {
    return BookingState.isReadyForDepartureOrPrev(this.state);
  }

  get hasArrived(): boolean {
    return this.state.hasArrived;
  }

  get isCancelled(): boolean {
    return this.state.isCancelled;
  }

  hasAnyExtras(): boolean {
    return (
      this.extra_courtesy_vehicle_insurance ||
      this.extra_glass_treatment ||
      this.extra_wipers ||
      this.extra_washer_fluid
    );
  }

  public static empty(): Booking {
    return new Booking({
      uuid: 'empty',
      key: 'emptykey',
      reserved_time: null,
      reserved_date: null,
      insurance_company: null,
      insurance_type_of_loss: 'UNDEFINED',
      courtesy_vehicle_type: 'UNDEFINED',
      contact_name: null,
      contact_phone: null,
      contact_email: null,
      user_name: null,
      user_org_no: null,
      vehicle: {
        registration_no: null,
        brand: null,
        model: null,
        model_year: null,
        description: null,
      },
      workshop: {
        uuid: 'emptyworkshop',
        name: null,
        description: null,
        phone: null,
        email: null,
        opens_at: null,
        arrive_before: null,
        arrive_before_close_day_before: null,
        closes_at: null,
        closes_at_day_before: null,
        location: {latitude: null, longitude: null, google_place_id: null},
      },
    });
  }
}
